<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    name="email"
                    v-model="inputFields.email"
                    placeholder="Email"
                    autocomplete="email"
                    :isValid="!errorFields.email ? null : false"
                    :invalidFeedback="errorFields.email"
                    @keyup.enter="onLogin"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    name="password"
                    v-model="inputFields.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    @keyup.enter="onLogin"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton v-if="!isRequesting" @click="onLogin" color="primary" class="px-4">Login</CButton>
                      <preloader v-if="isRequesting" text="Logging in..." />
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CLink to="/register" class="btn d-lg-none btn-link">Register now!</CLink>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CLink
                  to="/register"
                  class="btn btn-outline-light btn-lg"
                >
                  Register Now!
                </CLink>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import authLogin from '@/services/authLogin'

export default {
  name: 'Login',
  title: 'Login',
  data () {
    return {
      inputFields: {
        email: '',
        password: ''
      },
      errorFields: {},
      isRequesting: !1
    }
  },
  methods: {
    async onLogin () {
      this.errorFields = {}
      const { email, password } = this.inputFields
      this.isRequesting = !0
      const returnData = await authLogin(email, password)
      if (returnData.type === 200) {
        this.$store.commit('ADD_LOGGED_USER_DATA', returnData.user)
        this.$store.commit('ADD_NOTIFICATION', { type: 'success', message: 'Logged in successully.' })
        this.$router.push({ name: 'Dashboard' })
        return
      }
      this.isRequesting = !1
      if (returnData.type === 422) {
        this.errorFields = returnData.errorFields
        return
      }
      this.$store.commit('ADD_NOTIFICATION', { type: 'error', message: returnData.message })
    }
  }
}
</script>
