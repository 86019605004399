import axios from '@/utils/axios'

export default async (email, password) => {
  const { type, payload } = await axios({
    url: 'login',
    method: 'POST',
    data: { email, password }
  })

  if (type === 200) {
    const { VUE_APP_LOGGED_TOKEN } = process.env
    const { user, access_token: token } = payload
    localStorage.setItem(VUE_APP_LOGGED_TOKEN, token)
    return { type: 200, user }
  }
  if (type === 422) {
    const { errors } = payload
    const errorFields = Object.keys(errors).reduce((retData, curKey) => {
      retData[curKey] = errors[curKey][0]
      return retData
    }, {})
    return { type: 422, errorFields }
  }
  const { message } = payload
  return { type, message }
}
